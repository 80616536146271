$navbar-height: 65px;
$navbar-progress-bar-height: 5px;

$primary-color: #0D6EFD;
$primary-color-surface: lighten($primary-color, 45%);
$primary-color-surface-text: darken($primary-color, 20%);
$primary-color-accent: darken($primary-color, 30%);

$accent-color: rgb(253, 197, 10);

$font-stack: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
