.container {
  padding: 1.5rem 15%;

  h1:first-child {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    padding: 20px 50px;
  }

  @media (max-width: 500px) {
    padding: 20px 20px;
  }
}
