@import "variables";

@mixin link {
  color: #0A58CA;
  &:hover {
    color: #6394d8;
  }
}

@mixin accessibility-shadow($color: adjust-color($primary-color, $alpha: -0.5)) {
  box-shadow: 0 0 0 0.25rem $color;
  outline: none;
}
