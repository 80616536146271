@import "variables";

.container {
  background-color: $primary-color;
  border-radius: 1rem;
  padding: 0.75rem 1rem;

  display: none;
  @media (max-width: 768px) {
    display: block;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;
      font-size: 1.5rem;

      &:not(:last-child) {
        margin-right: 1rem;
        &::after {
          content: '/';
          margin-left: 1rem;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      a {
        color: white;
      }
    }
  }
}
