@import 'variables';

.responsiveContainer {
  width: 100%;
  margin: 20px 0;
  padding: 1px;
  overflow-x: auto;

  .table {
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $primary-color;

    thead, thead th, td.header {
      font-weight: bold;
      color: $primary-color-surface-text;
      border-color: transparentize($primary-color-accent, 0.4);
      background-color: $primary-color-surface;
    }

    th, td {
      padding: 5px;
      border-collapse: collapse;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.45);

      button.sortButton {
        font: inherit;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
        border: none;
        outline: none;
        background: none;

        span {
          &.activeSort {

          }

          &.sortable {
            opacity: 0.5;
          }
        }
      }
    }

    tr {
      th {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      td {
        &:empty::before {
          content: '-';
          opacity: 0.4;
          color: $primary-color-accent;
        }
      }

      .gold {
        font-weight: bold;
        background-color: #fdd50d;
      }

      .silver {
        font-weight: bold;
        background-color: #cfcfcf;
      }

      .bronze {
        font-weight: bold;
        color: #fffcf3;
        background-color: #cf9050;
      }
    }
  }
}
