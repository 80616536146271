@import "variables";
@import "mixins";

body, html {
  margin: 0;
  padding: 0;
}

* {
  &:focus {
    @include accessibility-shadow;
  }

  &, ::after, ::before {
    box-sizing: border-box;
  }
}

h1, h2, h3, h4, h5, h6, a, p, li, td, th, button {
  font-family: $font-stack;
}

h1 {
  font-size: 2.5rem;

  svg.svg-inline--fa {
    margin-right: 0.5rem;
    font-size: 2.25rem;
    vertical-align: -0.1em;
    color: $accent-color;
  }

  span {
    color: gray;
    font-size: 2rem;
    display: block;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

p, td, th {
  line-height: 1.5em;
  color: #212529;
  font-size: 1rem;

  a {
    @include link;
  }
}
