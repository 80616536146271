@import "variables";
@import "mixins";

@mixin navbar-accessibility-shadow {
  &:focus {
    @include accessibility-shadow(rgba(255, 255, 255, 0.5))
  }
}

.nav {
  width: 100%;
  height: $navbar-height;
  background-color: $primary-color;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem $navbar-progress-bar-height;

  @media (max-width: 800px) {
    padding: 0 2rem $navbar-progress-bar-height;
  }

  .title {
    color: white;
    font-size: 2rem;
    @media (max-width: 300px) {
      font-size: 1rem;
    }

    @media (max-width: 850px) {
      font-size: 1.5rem;
    }

    a {
      color: white;
      text-decoration: none;
      vertical-align: middle;
      @include navbar-accessibility-shadow;
    }

    .logo {
      height: $navbar-height - 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;

      @media (max-width: 950px) {
        display: none;
      }
    }
  }

  .desktopMenuItems {
    list-style-type: none;
    padding: 0;

    @media (max-width: 768px) {
      display: none;
    }

    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 2rem;
      }

      font-size: 1.1rem;

      a {
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;

        &:hover, &.active {
          color: white;
        }

        @include navbar-accessibility-shadow;
      }
    }
  }

  .mobileMenuLink {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }

    background: rgba(255, 255, 255, 0.2) none;
    border: none;
    outline: none;

    padding: 0.6rem 0.75rem;
    border-radius: 5px;
    cursor: pointer;

    .mobileMenuIcon {
      color: white;
      font-size: 1rem;
    }
  }

  .mobileMenu {
    position: fixed;
    height: 0;
    width: 100%;
    top: $navbar-height - $navbar-progress-bar-height;
    left: 0;

    overflow: hidden;
    transition: height 200ms ease-in-out;

    &.show {
      height: calc(100vh - #{$navbar-height - $navbar-progress-bar-height});
    }

    @media (min-width: 768px) {
      display: none;
    }

    background-color: $primary-color;
    z-index: 1;

    menu {
      list-style-type: none;
      padding: 0 1.5rem;

      li {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  position: relative;
  .progressBarContainer {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: $navbar-progress-bar-height;
    background-color: white;

    .progressBar {
      position: absolute;
      height: 100%;
      background-color: $accent-color;
      border-bottom-right-radius: $navbar-progress-bar-height;
    }
  }
}
